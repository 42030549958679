.logo {
  width: 50px;
  /* Largeur du logo */
  height: 50px;
  /* Hauteur du logo */
  margin-right: 15px;
  /* Marge à droite du logo pour l'espacement */
  background-image: url('/public/images/logo_mosquee_thonon.png');
  /* Spécifiez le chemin de votre image de logo */
  background-size: cover;
  /* Ajustez la taille de l'image du logo */
}

body {
  background-color: #f5f5f5;
}

/* Centre le menu horizontalement */
.ant-menu-horizontal {
  display: flex;
  justify-content: center;
}

.content {
  padding-top: 15px;
  min-height: 450px;
}

.centered-content {
  display: flex;
  justify-content: center;
}

.centered-content-bk {
  display: block;
  text-align: center;
}

.centered-content-v {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Styles.css */
/* Par défaut, le conteneur utilise 50% de la largeur de l'écran */
.container-form {
  width: 50%;
  padding: 0 10px;
}

.carousel-container {
  margin: 150px 250px;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 350px;
  line-height: 180px;
  background: #001529;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.carousel-element {
  padding: 15px;
  color: #fff;
  line-height: 1.8;
  text-align: center;
}

.logo-carousel {
  width: 110px;
  height: 110px;
  margin: 0 auto;
  text-align: center;
  background-image: url('/public/images/logo_mosquee_thonon.png');
  background-size: cover;
}

/* Pour les écrans de taille moyenne (par exemple, tablettes), le conteneur utilise 70% */
@media screen and (max-width: 900px) {
  .container-form {
    width: 80%;
    padding: 0 10px;
  }

  .hidden-xs {
    display: none;
  }

  .carousel-container {
    margin: 50px 10px;
  }
}

/* Pour les écrans plus petits (par exemple, smartphones), le conteneur utilise 100% */
@media screen and (max-width: 480px) {
  .container-form {
    width: 100%;
    padding: 0 10px;
  }

  .hidden-xs {
    display: none;
  }

  .logo-carousel {
    width: 90px;
    height: 90px;
    margin: 0 auto;
    text-align: center;
    background-image: url('/public/images/logo_mosquee_thonon.png');
    background-size: cover;
  }
}

.menu-action-container {
  display: flex;
  margin-bottom: 20px;

  .label {
    margin-right: 15px;
  }

  .bt-action {
    margin-right: 15px;
  }
}

.d-flex {
  display: flex;
}

.container-full-width {
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
}

.filters-container {
  width: 25%;
  margin-right: 25px;
}

.result-container {
  width: 65%;
}

.logo-title {
  color: white;
  font-weight: 600;
  font-size: large;
}

.footer {
  text-align: center;
  font-family: "Poppins", "sans-serif";
}

.home {
  font-size: large;
  text-align: justify;
}

.form-errors {
  color: red;
}

.m-top-15 {
  margin-top: 15px;
}

.m-top-30 {
  margin-top: 30px;
}

.m-left-10 {
  margin-left: 10px;
}

.m-right-10 {
  margin-right: 10px;
}

.m-bottom-15 {
  margin-bottom: 15px;
}

.m-bottom-10 {
  margin-bottom: 10px;
}

.fw-bold {
  font-weight: bold;
}

.pad-10 {
  padding: 10px;
}

.container-nav-bi {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.container-nav-mono {
  display: flex;
  justify-content: right;
}

.popover-content {
  width: 250px;
}

.action-icon {
  margin-right: 8px;
}

.result-message {
  font-weight: bolder;
  font-size: large;
}

.insc-enfant-title {
  border-bottom: 3px solid #4FC3F7;
  color: #4FC3F7;
}

.enseignant-title {
  border-bottom: 3px solid #4FC3F7;
  color: #4FC3F7;
}

.classes-title {
  border-bottom: 3px solid #4FC3F7;
  color: #4FC3F7;
}

.insc-adulte-title {
  border-bottom: 3px solid #1A73E8;
  color: #1A73E8;
}

.admin-tarif-title {
  border-bottom: 3px solid orange;
  color: orange;
}

.admin-param-title {
  border-bottom: 3px solid orange;
  color: orange;
}

.adhesion-title {
  border-bottom: 3px solid orange;
  color: orange;
}

.login-button {
  width: 150px
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.inscription-closed {
  width: 150px;
  height: 150px;
  background-image: url('/public/images/closed.png');
  background-size: cover;
  justify-content: center;
  align-self: center;
}

.main-content-enseignant {
  margin: 0 auto;
  width: 50%;
  text-align: center;
}

.main-content-classes {
  margin: 0 20px;
  width: 80%;
  text-align: center;
}

.ant-collapse-header {
  color: #ffffff !important;
}

.ant-card-head-title {
  color: #f5f5f5 !important;
}

.ant-card {
  border: 1px solid #001529 !important;
  box-shadow: 0 5px 15px #001529 !important;
}

.classe-container {
  display: grid;
  grid-template-columns: 40% 1fr;
  gap: 25px;
  width: 100%;
  padding: 15px;
  transition: grid-template-columns 0.3s ease;
}

.search-result-container {
  display: grid;
  grid-template-columns: 25% 1fr;
  gap: 25px;
  width: 100%;
  padding: 20px;
}